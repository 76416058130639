export const cups2 = [



    {
        decoding: "async",
        src: "../gokul-images/pacific1.JPG",
        width: 4,
        height: 3
    },
    {
        decoding: "async",
        src: "../gokul-images/pacific2.JPG",
        width: 4,
        height: 3
    },

    {
        decoding: "async",
        src: "../gokul-images/pacific4.JPG",
        width: 1,
        height: 1
    }

];