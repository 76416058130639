export const satya = [
  {
    decoding: "async",
    src: "../gokul-images/satya1.JPG",
    width: 4,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/satya2.JPG",
    width: 8,
    height: 3,
  },
  // {
  //   decoding: "async",
  //   src: "../gokul-images/satya3.JPG",
  //   width: 4,
  //   height: 3,
  // },




  {
    decoding: "async",
    src: "../gokul-images/satya7.JPG",
    width: 4,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/satya8.JPG",
    width: 4,
    height: 2,
  },
  {
    decoding: "async",
    src: "../gokul-images/satya9.JPG",
    width: 4,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/blunt.JPG",
    width: 7,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/blunt2.JPG",
    width: 4,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/satya10.JPG",
    width: 1,
    height: 1,
  },

  {
    decoding: "async",
    src: "../gokul-images/satya11.JPG",
    width: 4,
    height: 3,
  },
  {
    decoding: "async",
    src: "../gokul-images/satya12.JPG",
    width: 4,
    height: 3,
  },
];
