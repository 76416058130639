export const cups = [

    {
        decoding: "async",
        src: "../gokul-images/box3.jpg",
        width: 4,
        height: 3
    },
    {
        decoding: "async",
        src: "../gokul-images/cup4.jpg",
        width: 4,
        height: 3
    },

    {
        decoding: "async",
        src: "../gokul-images/mini.jpg",
        width: 1,
        height: 1
    },

    // {
    //     decoding: "async",
    //     src: "../gokul-images/pacific1.JPG",
    //     width: 4,
    //     height: 3
    // },
    // {
    //     decoding: "async",
    //     src: "../gokul-images/pacific2.JPG",
    //     width: 4,
    //     height: 3
    // },

    // {
    //     decoding: "async",
    //     src: "../gokul-images/pacific4.JPG",
    //     width: 4,
    //     height: 3
    // },
    // {
    //     decoding: "async",
    //     src: "../gokul-images/mini1.JPG",
    //     width: 1,
    //     height: 1
    // }
];